import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Login,
  Register,
  NewOrder,
  NotFound,
  Orders,
  AboutUs,
  BusinessRules,
  CreateFeedback,
  OrderList,
  Feedbacks,
  UserSettings,
} from "../pages";
import { Toaster } from "react-hot-toast";

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/new-order" element={<NewOrder />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/business-rules" element={<BusinessRules />} />
          <Route path="/create-feedback" element={<CreateFeedback />} />
          <Route path="/order-list" element={<OrderList />} />
          <Route path="/feedbacks" element={<Feedbacks />} />
          <Route path="/user-settings" element={<UserSettings />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </>
  );
};

export default Router;
