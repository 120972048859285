import "./BusinessRules.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";

const BusinessRules = () => {
  return (
    <>
      <HeaderCompost backTo="/" pageName="regras de negócio" />
      <MainWrapper toggleDirection={true}>
        <section className="b-rules-text">
          <h1 className="green-txt bold">Regras de negócio</h1>
          <h2>Regra 30: Fornecimento e substituições de uniformes</h2>
          <p className="bold">
            Empregados que retiraram uniformes nos últimos 6 meses
          </p>
          <ul>
            <li>Não deverão realizar novas retiradas de uniformes</li>
          </ul>
          <p className="bold">Empregados híbridos acessando as operações</p>
          <ul>
            <li>
              Apenas se necessário, realizar a retirada considerando o limite de
              até 3 uniformes
            </li>
          </ul>
          <p className="bold">Empregados operacionais</p>
          <ul>
            <li>
              Apenas se necessário, realizar a retirada considerando o limite de
              até 3 uniformes
            </li>
          </ul>
          <p className="bold">
            Novos profissionais Operacionais (que estão entrando na Vale neste
            momento)
          </p>
          <ul>
            <li>
              Realizar a retirada considerando o limite de até 5 uniformes
            </li>
          </ul>
          <br />
          <br />
          <small className="gray-txt">
            Em caso de dúvidas, entrar em contato com
            <span className="bold"> suellen.iraci@vale.com</span> ou
            <span className="bold"> guilherme.augusto@vale.com</span>.
          </small>
          <br />
          <br />
          <hr />
          <h2>Regra 46: Requisições de uniformes</h2>
          <p>
            Visando otimizar o estoque, foi elaborada e veiculada a Regra de
            Negócio 30, onde foram definidas algumas regras para consumo de
            uniformes. Complementando esta regra, seguem novas orientações e o
            fluxo para requisição de novos uniformes.
          </p>
          <p>
            No período de transição das modelagens dos uniformes, devemos
            consumir o estoque já comprado pela Vale, até que seja finalizado e,
            após o término deste, todas as áreas da Vale deverão adquirir os
            uniformes via Requisição de Compra (despesa), com a antecedência
            necessária ao uso. As orientações passam a ser válidas a partir de
            24/05 ou com o término dos estoques, o que ocorrer primeiro.
          </p>
          <br />
          <small className="gray-txt">
            Em caso de dúvidas, entrar em contato com{" "}
            <span className="bold">laura.felipe@vale.com</span> ou
            <span className="bold"> guilherme.augusto@vale.com</span>.
          </small>
        </section>
      </MainWrapper>
    </>
  );
};

export default BusinessRules;
