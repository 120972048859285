import "./Orders.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import Modal from "../../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { handleDate } from "../../utils/Helpers";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";

const Orders = () => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [productList, setProductList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderTitle, setOrderTitle] = useState("");
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  const handleProductList = async () => {
    try {
      setSpinner(true);

      const response = await axios.get(`${process.env.REACT_APP_API}products`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("customer")).token
          }`,
        },
      });

      setProductList(response.data);

      setSpinner(false);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log(error);

        toast.error(`Erro inesperado...`, {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  const handleOrderList = async () => {
    try {
      setSpinner(true);

      const response = await axios.get(`${process.env.REACT_APP_API}orders`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("customer")).token
          }`,
        },
      });

      setOrderList(response.data);

      if (response.data.length === 0) {
        toast.error("Sem pedidos para mostrar.", {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log(error);

        toast.error(`Erro inesperado...`, {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  useEffect(() => {
    handleProductList();
    handleOrderList();
  }, []);

  const defineBlStyles = (status) => {
    let gradPosition;

    switch (status) {
      case "CREATED":
        gradPosition = 0;
        break;
      case "INPROGRESS":
        gradPosition = (100 / 6) * 1;
        break;
      case "TOAPPROVE":
        gradPosition = (100 / 6) * 2;
        break;
      case "INTRANSIT":
        gradPosition = (100 / 6) * 3;
        break;
      case "PACKING":
        gradPosition = (100 / 6) * 4;
        break;
      case "PACKED":
        gradPosition = (100 / 6) * 5;
        break;
      case "DONE":
        gradPosition = (100 / 6) * 6;
        break;
      case "CANCELED":
        return {
          backgroundColor: "var(--red)",
        };
    }

    return {
      backgroundImage: `linear-gradient(to right, var(--green) ${gradPosition}%, rgba(0,0,0,0.3) ${gradPosition}%)`,
    };
  };

  const defineMdPosition = (status) => {
    let leftPosition;

    switch (status) {
      case "CREATED":
        leftPosition = 0;
        break;
      case "INPROGRESS":
        leftPosition = (100 / 6) * 1 - 0.5;
        break;
      case "TOAPPROVE":
        leftPosition = (100 / 6) * 2 - 0.5;
        break;
      case "INTRANSIT":
        leftPosition = (100 / 6) * 3 - 0.5;
        break;
      case "PACKING":
        leftPosition = (100 / 6) * 4 - 0.5;
        break;
      case "PACKED":
        leftPosition = (100 / 6) * 5 - 0.5;
        break;
      case "DONE":
        leftPosition = (100 / 6) * 6 - 0.5;
        break;
      case "CANCELED":
        return {
          display: "none",
        };
    }

    return {
      left: leftPosition + "%",
    };
  };

  const defineStatusText = (status) => {
    let message;

    switch (status) {
      case "CREATED":
        message = "Você realizou o pedido, aguarde as próximas atualizações";
        break;
      case "INPROGRESS":
        message = "Seu produto foi solicitado";
        break;
      case "TOAPPROVE":
        message = "Aguardando aprovação do supervisor";
        break;
      case "INTRANSIT":
        message = "Estamos aguardando a entrega do seu produto";
        break;
      case "PACKING":
        message = "Seu produto está em separação";
        break;
      case "PACKED":
        message = "Seu produto está pronto pra ser retirado";
        break;
      case "DONE":
        message = "Este produto já foi entrege";
        break;
      case "CANCELED":
        message = "Cancelado";
    }

    return message;
  };

  return (
    <div className="orders-page">
      <HeaderCompost backTo="/new-order" pageName="acompanhar pedidos" />
      <MainWrapper toggleDirection={true}>
        <div className="cards-wrp">
          {spinner && <div className="spinner green"></div>}
          {orderList &&
            orderList.map((order, index) => {
              return (
                <div className="order-card" key={order.id}>
                  <h3 className="bold order-card-title"> PEDIDO {order.id}</h3>
                  <p className="thin-border">
                    Pedido realizado dia
                    <span className="bold">
                      {" "}
                      {handleDate(order.orderHistory[0].createdAt)}
                    </span>
                  </p>
                  <br />
                  <p className="bold thin-border">{`${
                    order.userProduct.length
                  } ${order.userProduct.length > 1 ? "itens" : "item"}`}</p>
                  {order.status === "DONE" ? (
                    <p className="bold green-txt thin-border">Entregue</p>
                  ) : order.status === "CANCELED" ? (
                    <p className="bold red-txt thin-border">Cancelado</p>
                  ) : (
                    ""
                  )}
                  {order.status === "CANCELED" ? (
                    <></>
                  ) : (
                    <button
                      className="track-btn"
                      type="button"
                      onClick={(e) => {
                        setOrderDetails(
                          orderList.find((name) => name.id === order.id)
                            .userProduct
                        );
                        setOrderTitle(index + 1);
                        setShowOrderDetails(!showOrderDetails);
                      }}
                    >
                      Acompanhar
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </MainWrapper>
      <Modal show={showOrderDetails} icon="box.svg">
        <h2 className="bold">PEDIDO {orderTitle}</h2>
        <br />
        {orderDetails.map((item) => {
          return (
            <div className="modal-item green-txt" key={item.id}>
              <h3 className="bold">{item.product.name}</h3>
              <p>Quantidade: {item.quantity}</p>
              {productList.find((prod) => prod.id === item.product.id).size && (
                <p>
                  Tamanho:{" "}
                  {productList.find((prod) => prod.id === item.product.id).size}
                </p>
              )}
              <div className="item-statusbar-wrp">
                <div className="markers">
                  <div className="markerline"></div>
                  <div className="markerline"></div>
                  <div className="markerline"></div>
                  <div className="markerline"></div>
                  <div className="markerline"></div>
                  <div className="markerline"></div>
                  <div className="markerline"></div>
                </div>
                <div className="timeline" style={defineBlStyles(item.status)}>
                  <div
                    className="markerdot"
                    style={defineMdPosition(item.status)}
                  ></div>
                </div>
                <p
                  className="bold statustext"
                  style={
                    item.status === "DONE"
                      ? { color: "var(--green)" }
                      : item.status === "CANCELED"
                      ? { color: "var(--red)" }
                      : {}
                  }
                >
                  {defineStatusText(item.status)}
                </p>
                <small className="updtdate gray-txt">
                  Última atualização no dia{" "}
                  {handleDate(
                    item.userProductHistory[item.userProductHistory.length - 1]
                      .createdAt
                  )}
                </small>
              </div>
            </div>
          );
        })}
      </Modal>
    </div>
  );
};

export default Orders;
