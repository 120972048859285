import "./Button.css";

const Button = (props) => {
  const isAdmin = () => {
    return JSON.parse(localStorage.getItem("customer"))?.admin;
  };

  const classes = `main-btn ${isAdmin() ? "turn-green" : ""}`;

  return (
    <button className={classes} type="button" onClick={props.click} style={props.center ? { margin: "auto" } : {}}>
      {props.text}
    </button>
  );
};

export default Button;
