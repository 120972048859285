import "./NewOrder.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import Button from "../../components/Button/Button";
import SearchInput from "../../components/SearchInput/SearchInput";
import Modal from "../../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";

const NewOrder = () => {
  const navigate = useNavigate();

  /*
   * For clarity, i'll be using a naming convention to the 'products' array:
   * products > product > item
   *
   * -"products" contains a list of every product available.
   *
   * -"product" contains its name on its index 0, and a list of items on index 1.
   *
   * -"item" represents a singular item inside the index 1 of a "product", this one
   * contains the relevant ID, size and description to the item.
   */

  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState({});
  /* selecetItem gets populated before user adds an item to orderItems */
  const [selectedItem, setSelectedItem] = useState({
    itemId: 0,
    itemName: "",
    itemQuantity: 1,
    itemSize: "",
  });
  const [orderItems, setOrderItems] = useState([]);

  const [orderCount, setOrderCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  /* Toggles for modals and other DOM elements */
  const [spinner, setSpinner] = useState(false);
  const [showClearSearchBtn, setShowClearSearchBtn] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);

  const handleProducts = async () => {
    try {
      setSpinner(true);

      const response = await axios.get(`${process.env.REACT_APP_API}products`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("customer")).token
          }`,
        },
      });

      const productList = Object.entries(
        Object.groupBy(response.data, (item) => {
          return item.name;
        })
      );

      setProducts(productList);
      setSpinner(false);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(`Erro inesperado... Você ainda está logado?`, {
          position: "bottom-center",
          duration: 5000,
        });
      }

      console.log(error);

      setSpinner(false);
    }
  };

  useEffect(() => {
    handleProducts();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);

    if (e.target.value === "") {
      setShowClearSearchBtn(false);
    } else {
      setShowClearSearchBtn(true);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setShowClearSearchBtn(false);
  };

  const addItemToModal = (itemName) => {
    let matchingProduct = products.find((e) => e[0] === itemName);

    /* sort by item size */
    matchingProduct[1] = matchingProduct[1].sort((a, b) => a.size - b.size);

    setSelectedProduct(matchingProduct);

    setSelectedItem({
      itemId: matchingProduct[1][0].id,
      itemName: matchingProduct[0],
      itemQuantity: 1,
      itemSize: matchingProduct[1][0].size,
    });
  };

  const updateIdBySize = (selectedSize) => {
    const foundMatchingProd = selectedProduct[1].find(
      (product) => product.size === selectedSize
    );

    setSelectedItem({
      ...selectedItem,
      itemId: foundMatchingProd.id,
      itemSize: selectedSize,
    });
  };

  const addToOrder = () => {
    if (selectedItem.itemQuantity <= 0) {
      toast.error(`A quantidade precisa ser maior que 0.`, {
        position: "bottom-center",
        duration: 5000,
      });

      return;
    } else if (
      selectedItem.itemQuantity > 3 &&
      selectedItem.itemQuantity < 1000
    ) {
      toast.error(`Até 3 unidades permitidas para cada item.`, {
        position: "bottom-center",
        duration: 5000,
      });

      return;
    } else if (selectedItem.itemQuantity >= 1000) {
      toast.error(`Quantidade não permitida.`, {
        position: "bottom-center",
        duration: 8000,
      });

      return;
    }

    setOrderCount(orderCount + parseInt(selectedItem.itemQuantity));

    setOrderItems([
      ...orderItems,
      {
        productId: selectedItem.itemId,
        name: selectedItem.itemName,
        quantity: selectedItem.itemQuantity,
        size: selectedItem.itemSize,
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const list = orderItems;
    const newList = list.toSpliced(index, 1);
    setOrderItems(newList);
    setOrderCount(orderCount - list[index].quantity);
  };

  const confirmOrder = async () => {
    const list = [];
    for (const item of orderItems) {
      list.push({
        productId: parseInt(item.productId),
        quantity: parseInt(item.quantity),
      });
    }

    try {
      if (orderItems.length !== 0) {
        await axios.post(`${process.env.REACT_APP_API}create-order`, list, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("customer")).token
            }`,
          },
        });

        toast.success(
          `Seu pedido foi enviado com sucesso!\nVocê será redirecionado...`,
          {
            position: "bottom-center",
            duration: 3000,
          }
        );

        setOrderItems([]);

        setTimeout(() => {
          navigate("/orders");
        }, 3000);
      } else {
        toast.error(`Seu pedido está vazio.`, {
          position: "bottom-center",
          duration: 5000,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log(error);

        toast.error(`Ocorreu um erro ao enviar seu pedido.`, {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  return (
    <div className="new-order-page">
      <HeaderCompost backTo="/" pageName="novo pedido" />
      <MainWrapper toggleDirection={true}>
        <div className="search-wrp">
          <div className="btn-wrp">
            <Button text="ver pedido" click={() => setShowOrder(!showOrder)} />
            <p className="item-counter">{orderCount}</p>
          </div>

          <SearchInput
            onChange={(e) => handleSearch(e)}
            showClearBtn={showClearSearchBtn}
            onClearBtn={() => handleClearSearch()}
            searchContent={searchQuery}
          />
        </div>

        <div className="card-list-wrp">
          {spinner && <div className="spinner green"></div>}

          {/* displays product list */}
          {products
            .filter(
              (item) =>
                item[0].includes(searchQuery.toUpperCase()) ||
                searchQuery === ""
            )
            .map((item) => {
              return (
                <button
                  className="item-card"
                  key={item[1][0].id}
                  onClick={() => {
                    addItemToModal(item[0]);
                    setShowProduct(!showProduct);
                  }}
                >
                  <div className="item-text-btn">
                    <h3 className="item-card-title">{item[0]}</h3>
                    <p className="item-card-description">
                      {item[1][0].description}
                    </p>
                  </div>
                  {item[1][0].image && (
                    <img
                      className="item-card-image"
                      src={`${process.env.REACT_APP_API_PHOTO}${item[1][0].image}`}
                      alt="Imagem do produto"
                    />
                  )}
                </button>
              );
            })}
        </div>
      </MainWrapper>
      <Modal show={showProduct} icon="box-open.svg">
        {selectedProduct[1] && (
          <div className="modal-card-wrp">
            {selectedProduct[1][0].image ? (
              <img
                className="modal-card-image"
                src={
                  selectedProduct[1][0].image
                    ? `${process.env.REACT_APP_API_PHOTO}${selectedProduct[1][0].image}`
                    : ""
                }
                alt="Imagem do produto"
              />
            ) : (
              <img
                className="modal-card-image"
                src="/assets/icons/file-xmark.svg"
                alt="Imagem do produto"
                style={{
                  padding: "2rem",
                }}
              />
            )}
            <div className="modal-card-info-wrp">
              <div>
                <h3 className="bold green-txt">{selectedProduct[0]}</h3>
                <p>{selectedProduct[1][0].description}</p>
              </div>
              <div className="modal-selectors">
                <div>
                  <label className="label" htmlFor="amount">
                    Quantidade
                  </label>
                  <input
                    className="item-amount-input"
                    id="amount"
                    type="number"
                    min={1}
                    max={3}
                    value={selectedItem.itemQuantity}
                    onChange={(e) =>
                      setSelectedItem({
                        ...selectedItem,
                        itemQuantity: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label className="label" htmlFor="sizes">
                    Tamanho
                  </label>
                  <select
                    className={
                      selectedProduct[1][0].size
                        ? "item-sizes-select"
                        : "item-sizes-select disabled"
                    }
                    id="sizes"
                    onChange={(e) => updateIdBySize(e.target.value)}
                  >
                    {selectedProduct[1][0].size &&
                      selectedProduct[1].map((details, index) => {
                        return <option key={index}>{details.size}</option>;
                      })}
                  </select>
                </div>
              </div>
              <button
                className="add-to-order-btn"
                type="button"
                onClick={() => {
                  addToOrder();
                  setShowProduct(!showProduct);
                }}
              >
                Adicionar ao pedido
              </button>
            </div>
          </div>
        )}
      </Modal>
      <Modal show={showOrder} icon="box.svg">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Quantidade</th>
              <th>Tamanho</th>
              <th></th>
            </tr>
          </thead>
          {orderItems.map((item, index) => {
            return (
              <tbody key={index + 1}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.size}</td>
                  <td>
                    <button
                      className="trash-btn"
                      onClick={() => handleRemoveItem(index)}
                    ></button>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className="confirm-order-btn">
          <Button
            text="efetuar pedido"
            click={() => setShowConfirmOrder(!showConfirmOrder)}
          ></Button>
        </div>
      </Modal>
      <Modal show={showConfirmOrder} icon="warning.svg">
        <p
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "600" }}
        >
          Por favor, verifique se os itens e quantidades estão corretos antes de
          enviar!
        </p>
        <br />
        <br />
        <Button text="confirmar pedido" center={true} click={confirmOrder} />
      </Modal>
    </div>
  );
};

export default NewOrder;
