import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";

const useCustomer = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const handleUser = async () => {
    const storage = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")) : false;

    if (!storage) return navigate("/login");

    try {
      const response = await axios.get(`${process.env.REACT_APP_API}get-user/${storage.id}`, {
        headers: {
          Authorization: `Bearer ${storage.token}`,
        },
      });
      setUser(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log(error);

        toast.error(`Ocorreu um erro ao autenticar.`, {
          position: "bottom-center",
          duration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    handleUser();
  }, []);

  return [user];
};

export default useCustomer;
