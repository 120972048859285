import "./HeaderCompost.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomer from "../../hooks/useCustomer";
import { Link } from "react-router-dom";

const HeaderCompost = (props) => {
  const [user] = useCustomer();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const avatar = `${process.env.REACT_APP_API_PHOTO}${user.photo}`;

  const logOut = () => {
    localStorage.removeItem("customer");

    navigate("/login");
  };

  return (
    <div className="header-content">
      <header>
        <div className="left-side-content">
          <Link to="/">
            <img id="logo-header" src="/assets/img/logo-sfe.svg" alt="Logo" />
          </Link>
          {props.backTo && <Link to={props.backTo} className="nav-back-btn"></Link>}
          <p className="green-txt nav-current-page">{props.pageName}</p>
        </div>
        <div className="right-side-content">
          <span id="user-name">{user.name}</span>
          <div
            id="user-avatar-wrp"
            style={
              user.photo !== ""
                ? { backgroundImage: `url('${avatar}')` }
                : {
                    backgroundImage: `url("/public/assets/icons/circle-user.svg")`,
                  }
            }
          ></div>
          <button id="dropdown-btn" type="button" onClick={() => setShowDropdown(!showDropdown)}></button>
        </div>
        {showDropdown && (
          <div id="dropdown">
            <Link className="dropdown-item" to="/user-settings">
              Configurações da Conta
            </Link>
            <Link className="dropdown-item" to="/create-feedback">
              Feedback
            </Link>
            <Link className="dropdown-item" to="/about-us">
              Sobre Nós
            </Link>
            <button id="logout-btn" type="button" onClick={logOut}>
              Sair
              <img src="/assets/icons/log-out.svg" alt="ìcone de Log Out" width={16} />
            </button>
          </div>
        )}
      </header>
      {showDropdown && <div id="close-dropdown" onClick={() => setShowDropdown(!showDropdown)}></div>}
    </div>
  );
};

export default HeaderCompost;
