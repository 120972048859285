import "./AboutUs.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import {
  MapContainer,
  Marker,
  TileLayer,
  Rectangle,
  Tooltip,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useState } from "react";

const AboutUs = () => {
  const [mapState, setMapState] = useState(null);

  const marker = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + "/assets/icons/dot.svg",
    iconSize: new L.Point(10, 10),
    iconAnchor: new L.Point(10, 20),
  });

  const dot = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + "/assets/icons/circle-dashed.svg",
    iconSize: new L.Point(10, 10),
    iconAnchor: new L.Point(10, 20),
  });

  return (
    <>
      <HeaderCompost backTo="/" pageName="Sobre nós" />
      <MainWrapper toggleDirection={true}>
        <section className="about-text">
          <h1 className="green-txt bold">Sobre nós</h1>
          <p>
            A ideia da ferramentaria da elétrica foi levantada dia 30 de abril
            de 2022. No mês de novembro foi inciada a alocação do espaço físico
            que costumava ser no laboratório da oficina elétrica, então, no dia
            09 de novembro de 2023, ela foi movimentada e atualmente está
            localizada na oficina de diversos da recpetora e tem como o objetivo
            atender demandas de obtenção e controle de EPI's da gerência
          </p>
          <p>
            A implementação da ferramentaria da elétrica possibilitou melhores
            condições de trabalho, além de mitigar riscos de rotina e gerar
            grande ecônomia de custo e mão de obra.
          </p>
        </section>
        <section className="about-links">
          <a
            className="green-txt bold"
            href="https://prezi.com/view/1CuKQ8CsMkudxetfOR3Q/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apresentação 2024
          </a>
        </section>
        <div id="map-wrp">
          <MapContainer
            center={[-20.266407, -40.2527784]}
            zoom={18}
            ref={mapState}
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
            />
            <Marker icon={marker} position={[-20.266394, -40.252643]}>
              <Tooltip direction="top" offset={[-9, -25]} opacity={1} permanent>
                Ferramentaria da Elétrica
              </Tooltip>
            </Marker>
            <Marker icon={dot} position={[-20.266406, -40.252923]}>
              <Tooltip
                direction="bottom"
                offset={[-5, -5]}
                opacity={1}
                permanent
              >
                CTM
              </Tooltip>
            </Marker>
            {/* <Rectangle
              bounds={([-20.266394, -40.252643], [-20.266394, -40.252643])}
              pathOptions={{ color: "black" }}
            >
              <Tooltip
                direction="bottom"
                offset={[0, 20]}
                opacity={1}
                permanent
              >
                permanent Tooltip for Rectangle
              </Tooltip>
            </Rectangle> */}
          </MapContainer>
        </div>
      </MainWrapper>
    </>
  );
};

export default AboutUs;
