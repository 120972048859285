import "./CreateFeedback.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import Button from "../../components/Button/Button";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const CreateFeedback = () => {
  const [message, setMessage] = useState("");

  const handleSendFeedback = async () => {
    try {
      const sendFeedback = await axios.post(
        `${process.env.REACT_APP_API}create-feedback`,
        {
          name: JSON.parse(localStorage.getItem("customer")).name,
          message,
        }
      );

      toast.success(sendFeedback.data.message, {
        position: "bottom-center",
        duration: 5000,
      });

      setMessage("");
    } catch (error) {
      toast.error(`Ocorreu um erro ao enviar o feedback.`, {
        position: "bottom-center",
        duration: 5000,
      });

      console.log(error);
    }
  };

  return (
    <>
      <HeaderCompost backTo="/" pageName="feedback" />
      <MainWrapper toggleDirection={true}>
        <section className="feedback-block">
          <h1 className="bold green-txt">Algum comentário ou sugestão?</h1>
          <textarea
            id="feedback-area"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <Button text="enviar feedback" click={() => handleSendFeedback()} />
        </section>
      </MainWrapper>
    </>
  );
};

export default CreateFeedback;
