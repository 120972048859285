import "./Home.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const menuBtns = () => {
    if (JSON.parse(localStorage.getItem("customer"))?.admin) {
      return [
        {
          text: "painel de pedidos",
          route: "/order-list",
        },
        {
          text: "novo pedido",
          route: "/new-order",
        },
        {
          text: "feedbacks",
          route: "/feedbacks",
        },
        {
          text: "acompanhar pedidos",
          route: "/orders",
        },
      ];
    } else {
      return [
        {
          text: "novo pedido",
          route: "/new-order",
        },
        {
          text: "acompanhar pedidos",
          route: "/orders",
        },
        {
          text: "regras de negócio",
          route: "/business-rules",
        },
        {
          text: "sobre nós",
          route: "/about-us",
        },
      ];
    }
  };

  return (
    <div className="home-page">
      <HeaderCompost backTo="/" pageName="home" />
      <MainWrapper isTransparent={true}>
        <div className="menu-list-wrp">
          {menuBtns().map((btn) => {
            return <Button text={btn.text} click={() => navigate(btn.route)} key={btn.route} />;
          })}
        </div>
        <div className="white-block">
          <img src="/assets/icons/screwdriver.svg" alt="Icone ferramentaria" />
          <h2 className="bold welcome-txt">Boas Vindas!</h2>
          <p id="text-message" className="green-txt">
            Faça agora suas requisições de uniformes e EPIs!
          </p>
        </div>
      </MainWrapper>
    </div>
  );
};

export default Home;
