import React, { useState, useEffect } from "react";
import "./OrderList.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import Accordion from "../../components/Accordion/Accordion";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

const OrderList = () => {
  const navigate = useNavigate();
  const [allOrders, setAllOrders] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const isAdmin = () => {
    return JSON.parse(localStorage.getItem("customer")).admin;
  };

  const groupBy = (array, keyFn) => {
    return array.reduce((result, item) => {
      const key = keyFn(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});
  };

  const handleAllOrders = async () => {
    try {
      setSpinner(true);

      const response = await axios.get(`${process.env.REACT_APP_API}orders`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("customer")).token
          }`,
        },
      });

      const groupedOrders = groupBy(response.data, (item) => {
        return (
          item.manager.description +
          " - CC#" +
          item.manager.costCenter +
          " / " +
          item.manager.supervisor +
          " " +
          item.manager.registration
        );
      });

      const list = Object.entries(groupedOrders);

      setAllOrders(list);
      setSpinner(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log(error);

        toast.error("Erro ao solicitar lista de pedidos", {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  useEffect(() => {
    handleAllOrders();
  }, []);

  const handlePageChange = (pageNumber) => {
    console.log("Page changed to:", pageNumber);
  };

  return (
    <>
      <HeaderCompost backTo="/" pageName="painel de pedidos" />
      {isAdmin() ? (
        <div className="OrderList">
          {spinner && <div className="spinner green"></div>}
          <div>
            {allOrders.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  title={item[0]}
                  orders={item[1]}
                  onPageChange={handlePageChange}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <p className="red-txt">
          Você não tem permissão para acessar esta página.
        </p>
      )}
    </>
  );
};

export default OrderList;
