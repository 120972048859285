export const handleDate = (fullDate) => {
  // incorrect time may cause to display a different, also incorrect day
  const date = {
    day: fullDate?.split("-")[2].split("T")[0],
    month: fullDate?.split("-")[1],
    year: fullDate?.split("-")[0],
  };

  return `${date.day}/${date.month}/${date.year}`;
};
