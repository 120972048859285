import "./MainWrapper.css";

const MainWrapper = (props) => {
  const classes = `page-content ${props.isTransparent ? "transparent" : ""} ${
    props.toggleDirection ? "column" : ""
  }`;

  return <main className={classes}>{props.children}</main>;
};

export default MainWrapper;
