import "./Modal.css";
import { useState } from "react";

const Modal = (props) => {
  const iconPath = `/assets/icons/${props.icon}`;
  const [closeModal, setCloseModal] = useState(false);
  return (
    <div className={props.show !== closeModal ? "show" : "hide"}>
      <div id="modal">
        <button
          id="close-btn"
          type="button"
          onClick={() => setCloseModal(!closeModal)}
        ></button>
        <div id="icon-wrp">
          <img alt="ícone do modal" src={iconPath} />
        </div>
        <div className="modal-children">{props.children}</div>
      </div>
      <div id="mask"></div>
    </div>
  );
};

export default Modal;
