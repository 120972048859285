import "./UserSettings.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const UserSettings = () => {
  const navigate = useNavigate();

  const [managerInfo, setManagerInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleUserInfo = async () => {
    try {
      setSpinner(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API}get-user/${
          JSON.parse(localStorage.getItem("customer")).id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("customer")).token
            }`,
          },
        }
      );

      setUserInfo(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log(error);

        toast.error("Erro inesperado.", {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  const handleManagerInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}managers`);

      const manager = response.data.find(
        (man) => man.id === userInfo.managerId
      );

      setManagerInfo(manager);

      setSpinner(false);
    } catch (error) {
      console.log(error);

      toast.error("Erro inesperado.", {
        position: "bottom-center",
        duration: 5000,
      });

      setSpinner(false);
    }
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    handleManagerInfo();
  }, [userInfo]);

  const handleDeleteUser = () => {
    try {
      axios.delete(`${process.env.REACT_APP_API}remove-user`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("customer")).token
          }`,
        },
      });

      toast.success(
        `Usuário deletado com sucesso!\nVocê será redirecionado...`,
        {
          position: "bottom-center",
          duration: 3000,
        }
      );

      localStorage.removeItem("customer");

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.log(error);

      toast.error(`Ocorreu um erro ao apagar sua conta.`, {
        position: "bottom-center",
        duration: 5000,
      });
    }
  };

  return (
    <>
      <HeaderCompost backTo="/" pageName="configurações da conta" />
      <MainWrapper toggleDirection={true}>
        <section className="user-settings-wrp">
          {spinner && <div className="spinner green"></div>}
          {userInfo && (
            <>
              <label className="gray-txt" htmlFor="username">
                Nome de usuário:
              </label>
              <p id="username" className="green-txt bold">
                {userInfo.name}
              </p>
              <label className="gray-txt" htmlFor="userreg">
                Matrícula:
              </label>
              <p id="userreg" className="green-txt bold">
                {userInfo.registration}
              </p>
              <label className="gray-txt" htmlFor="avatar">
                Avatar:
              </label>
              <img
                id="avatar"
                src={
                  userInfo.photo !== ""
                    ? `${process.env.REACT_APP_API_PHOTO}${userInfo.photo}`
                    : "/assets/icons/circle-user.svg"
                }
                alt="avatar do usuário"
              />
              <label className="gray-txt" htmlFor="usermail">
                Email:
              </label>
              <p id="usermail" className="green-txt bold">
                {userInfo.email}
              </p>
              <label className="gray-txt" htmlFor="usermanager">
                Supervisor:
              </label>
              {managerInfo && (
                <p id="usermanager" className="green-txt bold">
                  {`${managerInfo.supervisor} - ${managerInfo.description}`}
                </p>
              )}
              <label className="gray-txt" htmlFor="useradmin">
                Privilégios de administrador:
              </label>
              <p id="useradmin" className="green-txt bold">
                {userInfo.admin ? "Sim" : "Não"}
              </p>
              <button
                className="delete-user-btn"
                onClick={() => setShowModal(!showModal)}
              >
                Deletar conta
              </button>
            </>
          )}
        </section>
      </MainWrapper>
      <Modal icon="warning.svg" show={showModal}>
        <h2 className="in-modal-warning">
          Tem certeza que deseja apagar sua conta?
        </h2>
        <p className="red-txt in-modal-p">Essa ação é irreversível.</p>
        <br />
        <Button
          className="in-modal-btn"
          text="Deletar conta"
          center={true}
          click={() => handleDeleteUser()}
        />
      </Modal>
    </>
  );
};

export default UserSettings;
