import "./SearchInput.css";

const SearchInput = (props) => {
  return (
    <>
      <div className="search-input-wrp">
        <input
          className="search-input"
          type="text"
          placeholder="Buscar item..."
          onChange={props.onChange}
          value={props.searchContent}
        />
        {props.showClearBtn && (
          <button
            className="clear-search-btn"
            type="button"
            onClick={props.onClearBtn}
          ></button>
        )}
      </div>
    </>
  );
};

export default SearchInput;
