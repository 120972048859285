import "./NotFound.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found-page">
      <HeaderCompost backTo="/" pageName="not found" />
      <MainWrapper removeNav={true} isTransparent={true}>
        <div className="main">
          <img
            src="/assets/img/not-found.png"
            alt="animação de página não encontrada"
          />
          <p>Está perdido ou somente explorando?</p>
          <h1 className="bold">Não encontramos essa página.</h1>
          <Link className="green-txt" to="/">
            Voltar para <span className="bold">Home</span>
          </Link>
        </div>
      </MainWrapper>
    </div>
  );
};

export default NotFound;
