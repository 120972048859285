import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  const [registration, setRegistration] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const i of Object.entries({
      matricula: registration,
      senha: password,
    })) {
      if (i[1] === "") {
        toast.error(`O campo ${i[0]} é obrigatório!`, {
          position: "bottom-center",
          duration: 5000,
        });
        return;
      }
    }

    try {
      setSpinner(true);

      const customer = await axios.post(`${process.env.REACT_APP_API}signin`, {
        registration,
        password,
      });

      localStorage.setItem("customer", JSON.stringify(customer.data));

      setSpinner(false);
      navigate("/");
    } catch (error) {
      console.error(error);

      toast.error(`Matrícula ou senha inválida!`, {
        position: "bottom-center",
        duration: 5000,
      });

      setSpinner(false);
    }
  };

  return (
    <main className="login-page">
      <section id="lettering-wrp">
        <h1 className="green-txt">
          Você está no
          <span className="bold"> Sistema da Ferramentaria Elétrica</span>
        </h1>
        <h2 id="subtitle">Insira seus dados abaixo para entrar</h2>
      </section>

      <section className="form-block">
        <section>
          <img src="/assets/icons/screwdriver.svg" alt="ícone ferramentaria" />
        </section>
        <hr />
        <form>
          <input
            className="login-input"
            type="text"
            placeholder="Matrícula"
            value={registration}
            onChange={(e) => setRegistration(e.target.value)}
          />
          <div id="password-input-wrp">
            <input
              id="password-input"
              className="login-input"
              type={showPass ? "input" : "password"}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="show-pass-btn"
              type="button"
              style={{
                backgroundImage: `url('/assets/icons/eye-${
                  showPass ? "slash" : "open"
                }.svg')`,
              }}
              onClick={() => setShowPass(!showPass)}
            ></button>
          </div>
          <div id="login-btn-wrp">
            <small>
              Não possui uma conta?
              <br />
              <a className="green-txt" href="/register">
                Registre-se aqui!
              </a>
            </small>
            <button id="login-btn" onClick={(e) => handleSubmit(e)}>
              {spinner && <div className="spinner"></div>}
              {!spinner && <span>ENTRAR</span>}
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default Login;
