import "./Register.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [registration, setRegistration] = useState("");
  const [managerId, setManagerId] = useState(0);
  const [password, setPassword] = useState("");
  const [managers, setManagers] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [photoFileName, setPhotoFileName] = useState("Arquivo para foto");
  const [spinner, setSpinner] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    handleManagers();
  }, []);

  const handleManagers = async () => {
    try {
      const managers = await axios.get(`${process.env.REACT_APP_API}managers`);
      setManagers(managers.data);
    } catch (error) {
      console.log(error.managers.data);
    }
  };

  const handleManagerId = (e) => {
    setManagerId(parseInt(e.target.value));
  };

  const handleUserPhoto = (e) => {
    if (!e.target.files) {
      return;
    }

    const photoFile = e.target.files[0];

    if (photoFile.size <= 1000000) {
      // great
    } else {
      toast.error("A imagem precisa ter até 1MB.", {
        position: "bottom-center",
        duration: 5000,
      });
    }

    setPhoto(photoFile);
    setPhotoFileName(photoFile.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const i of Object.entries({
      nome: name,
      email: email,
      matricula: registration,
      equipe: managerId,
      senha: password,
      avatar: photo,
    })) {
      if (i[1] === "") {
        toast.error(`O campo ${i[0]} é obrigatório!`, {
          position: "bottom-center",
          duration: 5000,
        });
        return;
      }
    }

    const userData = {
      name,
      email,
      registration,
      managerId,
      password,
    };

    try {
      setSpinner(true);

      const form = new FormData();
      form.append("user", JSON.stringify(userData));
      form.append("photo", photo);

      await axios.post(`${process.env.REACT_APP_API}register`, form);

      setSpinner(false);

      toast.success(
        "Registro efetuado com sucesso!\nFaça login para continuar.",
        {
          position: "bottom-center",
          duration: 4000,
        }
      );

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error(error);

      if (error.response) {
        toast.error(error.response.data.message, {
          position: "bottom-center",
          duration: 5000,
        });
      } else {
        toast.error("Ocorreu um erro ao criar sua conta.", {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  return (
    <div className="register-page">
      <section id="lettering-wrp">
        <h1 className="green-txt">
          Você está no{" "}
          <span className="bold">Sistema da Ferramentaria Elétrica</span>
        </h1>
        <h2 id="subtitle">Insira seus dados para se cadastrar</h2>
      </section>

      <main>
        <section>
          <img src="/assets/icons/screwdriver.svg" alt="ícone ferramentaria" />
        </section>
        <hr />
        <form>
          <input
            className="register-input"
            type="text"
            placeholder="Nome"
            value={name}
            onChange={(i) => setName(i.target.value)}
          />
          <input
            className="register-input"
            type="text"
            placeholder="E-mail"
            value={email}
            onChange={(i) => setEmail(i.target.value)}
          />
          <select className="select" onChange={handleManagerId}>
            <option>Equipe</option>
            {managers &&
              managers.map((man) => {
                return (
                  <option key={man.id} value={man.id}>
                    {man.description}
                  </option>
                );
              })}
          </select>
          <div id="thinputs-wrp">
            <input
              className="thinput register-input"
              type="text"
              placeholder="Matrícula"
              value={registration}
              onChange={(i) => setRegistration(i.target.value)}
            />
            <div id="password-input-wrp">
              <input
                id="password-input"
                className="thinput register-input"
                type={showPass ? "input" : "password"}
                placeholder="Senha"
                value={password}
                onChange={(i) => setPassword(i.target.value)}
              />
              <button
                className="show-pass-btn"
                type="button"
                style={{
                  backgroundImage: `url('/assets/icons/eye-${
                    showPass ? "slash" : "open"
                  }.svg')`,
                }}
                onClick={() => setShowPass(!showPass)}
              ></button>
            </div>
          </div>
          <label id="img-input-label" htmlFor="img-input">
            {photoFileName}
          </label>
          <input
            className="register-input"
            id="img-input"
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            onChange={handleUserPhoto}
          />
          <div id="register-btn-wrp">
            <small>
              Já possui uma conta?
              <br />
              <a className="green-txt" href="/login">
                Acesse-a aqui!
              </a>
            </small>
            <button id="register-btn" onClick={(e) => handleSubmit(e)}>
              {spinner && <div className="spinner"></div>}
              {!spinner && <span>CADASTRAR</span>}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Register;
