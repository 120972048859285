import { useState } from "react";
import "./Accordion.css";
import Modal from "../Modal/Modal";
import Pagination from "../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import usePagination from "../../usePagination";

const Accordion = ({ title, orders, accordionId }) => {
  const navigate = useNavigate();

  const [order, setOrder] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const status = {
    CREATED: "Criado",
    INPROGRESS: "Em andamento",
    TOAPPROVE: "Agdo. aprovação",
    INTRANSIT: "Em trânsito",
    PACKING: "Em separação",
    PACKED: "Separado",
    DONE: "Concluído",
    CANCELED: "Cancelado",
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleSave = async () => {
    const data = [];
    const token = JSON.parse(localStorage.getItem("customer")).token;

    orders.forEach((order) => {
      order.userProduct.forEach((product) => {
        data.push({
          id: product.id,
          status: product.status,
          orderRequest: product.orderRequest || "",
          orderNumber: product.orderNumber || "",
        });
      });
    });

    const orderEdit = data.filter(
      (order) => order.orderRequest !== "" || order.orderNumber !== ""
    );

    try {
      setSpinner(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API}order/edit-status`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await axios.put(`${process.env.REACT_APP_API}order/edit`, orderEdit, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(response.data.message, {
        position: "bottom-center",
        duration: 5000,
      });

      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`Por favor faça login novamente.`, {
          position: "bottom-center",
          duration: 5000,
        });

        localStorage.removeItem("customer");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(`Ocorreu um erro ao salvar as alterações.`, {
          position: "bottom-center",
          duration: 5000,
        });
      }

      setSpinner(false);
    }
  };

  const { currentItems, currentPage, totalPages, paginate } = usePagination(
    orders,
    5
  );

  return (
    <div className="Accordion">
      <div className={isOpen ? "accordion open" : "accordion"}>
        <button
          type="button"
          data-toggle="collapse"
          data-target={`#${accordionId}`}
          aria-expanded={isOpen ? "true" : "false"}
          aria-controls={accordionId}
          className="toggle-height-btn"
          onClick={toggleAccordion}
        >
          {title}
          <img
            className={isOpen ? "rotate-icon" : ""}
            src="/assets/icons/caret-down.svg"
            alt="caret icon"
          />
        </button>
        {isOpen && (
          <>
            <section className="table-top-bar">
              <input
                type="text"
                value={search}
                placeholder="Pesquisar produtos"
                onChange={(e) => setSearch(e.target.value)}
              />
            </section>
            <table className="accordion-table">
              <thead>
                <tr>
                  <th>Nº do pedido</th>
                  <th>Pessoa</th>
                  <th>Status do pedido</th>
                  <th>Data do pedido</th>
                  <th>Detalhes</th>
                </tr>
              </thead>
              <tbody>
                {currentItems &&
                  currentItems.map((order, index) => (
                    <tr key={index}>
                      <td>{order?.id ?? ""}</td>
                      <td>{order?.user?.name ?? ""}</td>
                      <td>{status[order?.orderHistory.at(-1).status ?? ""]}</td>
                      <td>
                        {new Date(
                          order?.orderHistory.at(-1).createdAt
                        )?.toLocaleString() ?? ""}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="details-btn"
                          onClick={() => {
                            setShowModal(!showModal);
                            setOrder(order);
                          }}
                        >
                          <img
                            className="details-btn"
                            src="/assets/icons/circle-info.svg"
                            alt="icone de detalhes"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="table-footer">
              <p>Total de pedidos desta equipe: {orders?.length ?? 0}</p>
              <div style={{ width: "16%" }}>
                <button
                  onClick={handleSave}
                  style={{
                    position: "relative",
                    minWidth: "100%",
                    minHeight: "32px",
                  }}
                >
                  {spinner ? (
                    <div className="spinner green"></div>
                  ) : (
                    "salvar alterações"
                  )}
                </button>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={paginate}
            />
          </>
        )}
      </div>
      <Modal show={showModal} icon="box-open.svg">
        <p
          style={{
            color: "var(--green-light)",
            textAlign: "center",
            fontSize: "1.5rem",
            fontWeight: "600",
            marginBottom: "1rem",
          }}
        >
          PEDIDO #{order?.id ?? ""}
        </p>
        <table className="modal-table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Tamanho</th>
              <th>Qtd.</th>
              <th>Código</th>
              <th>Requisição/Reserva</th>
              <th>Num. do pedido</th>
              <th>Status</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {order?.userProduct &&
              order.userProduct.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.product?.name ?? ""}</td>
                    <td>{item?.product?.size ?? ""}</td>
                    <td>{item?.quantity ?? ""}</td>
                    <td>{item?.product?.code ?? ""}</td>
                    <td>
                      <input
                        type="text"
                        value={item?.orderRequest ?? ""}
                        onChange={(e) => {
                          if (item.orderRequest !== e.target.value) {
                            order.userProduct[index].orderRequest =
                              e.target.value;
                            setOrder({ ...order });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item?.orderNumber ?? ""}
                        onChange={(e) => {
                          if (item.orderNumber !== e.target.value) {
                            order.userProduct[index].orderNumber =
                              e.target.value;
                            setOrder({ ...order });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <select
                        value={item.status}
                        onChange={(e) => {
                          if (item.status !== e.target.value) {
                            item.status = e.target.value;
                            setOrder({ ...order });
                          }
                        }}
                      >
                        {Object.entries(status).map((s, i) => (
                          <option key={i} value={s[0]}>
                            {s[1]}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {new Date(
                        item?.userProductHistory.at(-1).createdAt
                      )?.toLocaleString() ?? ""}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Modal>
    </div>
  );
};

export default Accordion;
