import "./Feedbacks.css";
import HeaderCompost from "../../components/HeaderCompost/HeaderCompost";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";

const Feedbacks = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const isAdmin = () => {
    return JSON.parse(localStorage.getItem("customer")).admin;
  };

  const handleFeedbacks = async () => {
    try {
      setSpinner(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API}feedbacks`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("customer")).token
            }`,
          },
        }
      );

      setFeedbackList(response.data);
      setSpinner(false);
    } catch (error) {
      toast.error(`Erro inesperado...`, {
        position: "bottom-center",
        duration: 5000,
      });

      console.log(error);

      setSpinner(false);
    }
  };

  useEffect(() => {
    handleFeedbacks();
  }, []);

  return (
    <>
      <HeaderCompost backTo="/" pageName="feedbacks" />
      <MainWrapper toggleDirection={true}>
        {isAdmin() ? (
          <>
            <div className="feedbacks-wrp">
              {spinner && <div className="spinner green"></div>}
              {feedbackList.map((feedback) => {
                return (
                  <div className="feedback-card" key={feedback.id}>
                    <h2>{feedback.name}</h2>
                    <p>{feedback.message}</p>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <p className="red-txt">
            Você não tem permissão para acessar esta página.
          </p>
        )}
      </MainWrapper>
    </>
  );
};

export default Feedbacks;
